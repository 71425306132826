<script setup>

import ServiceCharges from "./tabs/ServiceCharges.vue";
import {ref} from "vue";
import Commissions from "./tabs/Commissions.vue";
import CreditLimit from "./tabs/CreditLimit.vue";
import MarketCreditLimit from "./tabs/MarketCreditLimit.vue";
import CreditScoring from "./tabs/CreditScoring.vue";
import InsuranceTab from "./tabs/InsuranceTab.vue";
import GlobalCreditLimit from "./tabs/GlobalCreditLimit.vue";
import PayoutLimit from "./tabs/PayoutLimit.vue";
import OverdueCharge from "./tabs/OverdueCharge.vue";
import FeePercentageSettingTab from "./tabs/FeePercentageSettingTab.vue";
import OrderCommission from "./tabs/OrderCommission.vue";

const currentTab = ref('service_and_commissions');

function changeTab(tab) {
    currentTab.value = tab;
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-12 col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="my-3">Settings</h5>
                        <div class="fm-menu">
                            <div class="list-group list-group-flush">
                                <a @click="changeTab('service_and_commissions')"
                                   :class="currentTab === 'service_and_commissions' ? 'active': ''"
                                   class="list-group-item py-1">
                                    <i class="bx bx-folder me-2">
                                    </i>
                                    <span>
                                        Service Charges
                                    </span>
                                </a>
<!--                                <a-->
<!--                                    @click="changeTab('commissions')"-->
<!--                                    :class="currentTab === 'commissions' ? 'active': ''"-->
<!--                                    class="list-group-item py-1">-->
<!--                                    <i class="bx bx-folder me-2"></i>-->
<!--                                    <span>-->
<!--                                        Commissions-->
<!--                                    </span>-->
<!--                                </a>-->
                                <a
                                    @click="changeTab('credit_limit')"
                                    :class="currentTab === 'credit_limit' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>
                                        Credit Limit
                                    </span>
                                </a>
                                <a
                                    @click="changeTab('market_credit_limit')"
                                    :class="currentTab === 'market_credit_limit' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>
                                        Market Credit Limit
                                    </span>
                                </a>
<!--                                <a-->
<!--                                    @click="changeTab('credit_scoring')"-->
<!--                                    :class="currentTab === 'credit_scoring' ? 'active': ''"-->
<!--                                    class="list-group-item py-1">-->
<!--                                    <i class="bx bx-folder me-2"></i>-->
<!--                                    <span>-->
<!--                                        Credit Scoring-->
<!--                                    </span>-->
<!--                                </a>-->
                                <a
                                    @click="changeTab('insurance_charge')"
                                    :class="currentTab === 'insurance_charge' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>Insurance Charge</span>
                                </a>
                                <a
                                    @click="changeTab('fee_settings')"
                                    :class="currentTab === 'fee_settings' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>Fee Settings</span>
                                </a>
                                <a
                                    @click="changeTab('global_credit_limit')"
                                    :class="currentTab === 'global_credit_limit' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>Global Credit Limit</span>
                                </a>
                                <a
                                    @click="changeTab('payoutLimit')"
                                    :class="currentTab === 'payoutLimit' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>Payout Limit</span>
                                </a>
                                <a
                                    @click="changeTab('overdue_charge')"
                                    :class="currentTab === 'overdue_charge' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>Overdue Charge</span>
                                </a>
                                <a
                                    @click="changeTab('order_commission')"
                                    :class="currentTab === 'order_commission' ? 'active': ''"
                                    class="list-group-item py-1">
                                    <i class="bx bx-folder me-2"></i>
                                    <span>Order Commissions</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <template v-if="currentTab === 'service_and_commissions'">
                            <ServiceCharges></ServiceCharges>
                        </template>
                        <template v-if="currentTab === 'commissions'">
                            <Commissions></Commissions>
                        </template>
                        <template v-if="currentTab === 'credit_limit'">
                            <CreditLimit></CreditLimit>
                        </template>
                        <template v-if="currentTab === 'market_credit_limit'">
                            <MarketCreditLimit></MarketCreditLimit>
                        </template>
                        <template v-if="currentTab === 'credit_scoring'">
                            <CreditScoring></CreditScoring>
                        </template>
                        <template v-if="currentTab === 'insurance_charge'">
                            <InsuranceTab></InsuranceTab>
                        </template>
                        <template v-if="currentTab === 'fee_settings'">
                            <FeePercentageSettingTab></FeePercentageSettingTab>
                        </template>
                        <template v-if="currentTab === 'global_credit_limit'">
                            <GlobalCreditLimit></GlobalCreditLimit>
                        </template>
                        <template v-if="currentTab === 'payoutLimit'">
                            <PayoutLimit></PayoutLimit>
                        </template>
                        <template v-if="currentTab === 'overdue_charge'">
                            <OverdueCharge></OverdueCharge>
                        </template>
                        <template v-if="currentTab === 'order_commission'">
                            <OrderCommission></OrderCommission>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


