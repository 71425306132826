<script setup>
import {onMounted, ref} from "vue";
import Swal from "sweetalert2";

const errors = ref([]);
const agent_commission = ref(0);
const market_leader_commission = ref(0);
const sales_officer_commission = ref(0);
const currentAgentValue = ref(false);
const currentMarketLeaderValue = ref(false);
const currentSalesOfficerValue = ref(false);

onMounted(() => {
    axios.get('/apis/v4/settings?keyword=order_commission')
        .then(resp => {

            if (resp.data.data) {
                resp.data.data.forEach((setting) => {
                    if(setting?.setting_name === 'agent_order_commission'){
                        agent_commission.value = setting?.settings?.agent_order_commission ?? 0;
                        currentAgentValue.value = setting?.settings?.active ?? false;

                    }else if(setting?.setting_name === 'market_leader_order_commission'){
                        market_leader_commission.value = setting?.settings?.market_leader_order_commission ?? 0;
                        currentMarketLeaderValue.value = setting?.settings?.active ?? false;

                    }else if(setting?.setting_name === 'sales_officer_order_commission'){
                        sales_officer_commission.value = setting?.settings?.sales_officer_order_commission ?? 0;
                        currentSalesOfficerValue.value = setting?.settings?.active ?? false;
                    }
                });
            }
        })
});
function validateCommission(){
    if(agent_commission.value === '' || agent_commission.value < 0 || agent_commission.value > 100){
        errors.value = {agent_commission: 'Agent commission must be between 0 and 100'};
        return false;
    }

    if(market_leader_commission.value === '' || market_leader_commission.value < 0 || market_leader_commission.value > 100){
        errors.value = {market_leader_commission: 'Market leader commission must be between 0 and 100'};
        return false;
    }

    if(sales_officer_commission.value === '' || sales_officer_commission.value < 0 || sales_officer_commission.value > 100){
        errors.value = {sales_officer_commission: 'Sales officer commission must be between 0 and 100'};
        return false;
    }

    return true;
}

const saveCommissions = () => {
    if(!validateCommission()){
        return;
    }

    axios.post('/apis/v4/settings', {
        'mass_insert': true,
        'mass_settings': [
            {
                'name': 'agent_order_commission',
                'settings': {
                    agent_order_commission: agent_commission.value,
                    active: true
                }
            },
            {
                'name': 'market_leader_order_commission',
                'settings': {
                    market_leader_order_commission: market_leader_commission.value,
                    active: true
                }
            },
            {
                'name': 'sales_officer_order_commission',
                'settings': {
                    sales_officer_order_commission: sales_officer_commission.value,
                    active: true
                }
            }
        ]
    }).then(resp => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "The order commissions has been saved",
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    }).catch(err => {
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: "The order commissions has not been saved",
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    });
}
</script>

<template>
    <div>
        <div class="tw-border-b tw-border-gray-200 tw-pb-2">
            <h3 class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Order Commissions</h3>
            <p class="tw-mt-2 tw-max-w-4xl tw-text-sm tw-text-gray-500">
                Set up commissions that will be applied to all orders.
            </p>
        </div>
        <div class="col-12">
            <div class="alert alert-info" role="alert">
                <p>Your current order commissions are set as follows:</p>
                <div class="col-6" style="font-weight: 600">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr v-if="currentAgentValue">
                                <td>Agent Commission</td>
                                <td>{{ agent_commission }}%</td>
                            </tr>
                            <tr v-if="currentMarketLeaderValue">
                                <td>Market Leader Commission</td>
                                <td>{{ market_leader_commission }}%</td>
                            </tr>
                            <tr v-if="currentSalesOfficerValue">
                                <td>Sales Officer Commission</td>
                                <td>{{ sales_officer_commission }}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12 mt-2">
                        <!--Label: Agent Commission, Attributes:agent_commission -->
                        <div class="form-floating">
                            <input type="number" class="form-control" id="agent_commission" v-model="agent_commission"
                                   aria-describedby="agent_commission-help"
                                   :class="[errors.agent_commission ? 'is-invalid': '',!errors.agent_commission && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                            <label for="agent_commission">Agent Commission %</label>

                            <div class="invalid-feedback" v-if="errors.agent_commission">
                                {{ errors.agent_commission.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <!--Label: Market Leader Commission, Attributes:market_leader_commission -->
                        <div class="form-floating">
                            <input type="number" class="form-control" id="market_leader_commission" v-model="market_leader_commission"
                                   aria-describedby="market_leader_commission-help"
                                   :class="[errors.market_leader_commission ? 'is-invalid': '',!errors.market_leader_commission && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                            <label for="market_leader_commission">Market Leader Commission %</label>

                            <div class="invalid-feedback" v-if="errors.market_leader_commission">
                                {{ errors.market_leader_commission.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <!--Label: Sales Officer Commission, Attributes:sales_officer_commission -->
                        <div class="form-floating">
                            <input type="number" class="form-control" id="sales_officer_commission" v-model="sales_officer_commission"
                                   aria-describedby="sales_officer_commission-help"
                                   :class="[errors.sales_officer_commission ? 'is-invalid': '',!errors.sales_officer_commission && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                            <label for="sales_officer_commission">Sales Officer Commission %</label>

                            <div class="invalid-feedback" v-if="errors.sales_officer_commission">
                                {{ errors.sales_officer_commission.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <button class="btn btn-secondary float-right" @click.prevent="saveCommissions">
                            Save
                            <i class="fa fa-save"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
